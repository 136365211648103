const VendorTooltips = {
  activeFh: "Switch on to activate vendor on fertighaus",
  activePapp: "Switch on to activate vendor on vendor map",
  hidden: "Switch on to hide vendor on fertighaus",
  name: "Full company name of the vendor",
  shortName: "Displayed name used on Fertighaus & in reportings",
  description: "Short description of the vendor's portfolio and services",
  operatesIn: "Country where the vendor offers its services",
  externalId: "",
  isFranchiseHq: "Switch on if the vendor is a franchise headquarter",
  franchiseHq: "If the vendor is a franchise partner, select the headquarter",
  sharePartnerOffices: "For franchise systems with shared houses: if activated, we show regional sales offices/license partners on house detail pages",
  addressLine1: "Street in vendor's address",
  addressLine2: "Additional details to vendor's address",
  postalCode: "ZIP code in vendor's address",
  city: "City in vendor's address",
  country: "Country in vendor's address",
  contactEmail: "General vendor contact email address",
  contactPhone: "General vendor contact phone number",
  brandingPlan: "",
  enableRegionalBranding: "",
  logo: "Logo used on fertighaus and reportings",
  officeProfilePicture: "Hero image displayed on the vendor map on fertighaus",
  catalogueLink: "Link to vendor catalogue, shared e.g. as part of handover emails to prospects and in user account",
  displayRegions: "ZIP codes for which vendor is shown as an option on fertighaus",
  msDisplayRegions: "ZIP codes for which vendor is shown as an option on the MS sites",
  imDisplayRegions: "ZIP codes for which vendor is shown as an option on the IM sites",
  topLocalPlacement: "Switch on to show vendor as top vendor in the vendor list on fertighaus for selected regions",
  topLocalPlacementHouseList: "Switch on to show vendor as top house in the vendor list on fertighaus for selected regions",
  topVendorPlacement: "Switch on to show vendor as top vendor in the vendor list on fertighaus",
  localPlacementRegionOverride: "ZIP codes for which vendor is shown as a top vendor",
  constructionMethods: "",
  expansionStages: "",
  filterPrice0To100k: "Switch on for this vendor to receive leads with prospects who have a budget of less than 100.000€",
  scorePrice0To100k: "Score how well this vendor fits prospects who have a budget of less than 100.000€",
  filterPrice100kTo150k: "Switch on for this vendor to receive leads with prospects who have a budget between 100.000€ and 150.000€",
  scorePrice100kTo150k: "Score how well this vendor fits prospects who have a budget between 100.000€ and 150.000€",
  filterPrice150kTo200k: "Switch on for this vendor to receive leads with prospects who have a budget between 150.000€ and 200.000€",
  scorePrice150kTo200k: "Score how well this vendor fits prospects who have a budget between 150.000€ and 200.000€",
  filterPrice200kTo250k: "Switch on for this vendor to receive leads with prospects who have a budget between 200.000€ and 250.000€",
  scorePrice200kTo250k: "Score how well this vendor fits prospects who have a budget between 200.000€ and 250.000€",
  filterPrice250kTo300k: "Switch on for this vendor to receive leads with prospects who have a budget between 250.000€ and 300.000€",
  scorePrice250kTo300k: "Score how well this vendor fits prospects who have a budget between 250.000€ and 300.000€",
  filterPrice300kTo350k: "Switch on for this vendor to receive leads with prospects who have a budget between 300.000€ and 350.000€",
  scorePrice300kTo350k: "Score how well this vendor fits prospects who have a budget between 300.000€ and 350.000€",
  filterPrice350kTo400k: "Switch on for this vendor to receive leads with prospects who have a budget between 350.000€ and 400.000€",
  scorePrice350kTo400k: "Score how well this vendor fits prospects who have a budget between 350.000€ and 400.000€",
  filterPrice400kTo450k: "Switch on for this vendor to receive leads with prospects who have a budget between 400.000€ and 450.000€",
  scorePrice400kTo450k: "Score how well this vendor fits prospects who have a budget between 400.000€ and 450.000€",
  filterPrice450kTo500k: "Switch on for this vendor to receive leads with prospects who have a budget between 450.000€ and 500.000€",
  scorePrice450kTo500k: "Score how well this vendor fits prospects who have a budget between 450.000€ and 500.000€",
  filterPrice500kTo550k: "Switch on for this vendor to receive leads with prospects who have a budget between 500.000€ and 550.000€",
  scorePrice500kTo550k: "Score how well this vendor fits prospects who have a budget between 500.000€ and 550.000€",
  filterPrice550kTo600k: "Switch on for this vendor to receive leads with prospects who have a budget between 550.000€ and 600.000€",
  scorePrice550kTo600k: "Score how well this vendor fits prospects who have a budget between 550.000€ and 600.000€",
  filterPrice600kToInf: "Switch on for this vendor to receive leads with prospects who have a budget of more than 600.000€",
  scorePrice600kToInf: "Score how well this vendor fits prospects who have a budget of more than 600.000€",
  filterSize0To75: "Switch on for this vendor to receive leads with prospects who whant to build a house with less than 75sqm",
  scoreSize0To75: "Score how well this vendor fits prospects who whant to build a house with less than 75sqm",
  filterSize75To100: "Switch on for this vendor to receive leads with prospects who whant to build a house with between 75 and 100sqm",
  scoreSize75To100: "Score how well this vendor fits prospects who whant to build a house with between 75 and 100sqm",
  filterSize100To120: "Switch on for this vendor to receive leads with prospects who whant to build a house with between 100 and 120sqm",
  scoreSize100To120: "Score how well this vendor fits prospects who whant to build a house with between 100 and 120sqm",
  filterSize120To140: "Switch on for this vendor to receive leads with prospects who whant to build a house with between 120 and 140sqm",
  scoreSize120To140: "Score how well this vendor fits prospects who whant to build a house with between 120 and 140sqm",
  filterSize140To160: "Switch on for this vendor to receive leads with prospects who whant to build a house with between 140 and 160sqm",
  scoreSize140To160: "Score how well this vendor fits prospects who whant to build a house with between 140 and 160sqm",
  filterSize160To180: "Switch on for this vendor to receive leads with prospects who whant to build a house with between 160 and 180sqm",
  scoreSize160To180: "Score how well this vendor fits prospects who whant to build a house with between 160 and 180sqm",
  filterSize180To200: "Switch on for this vendor to receive leads with prospects who whant to build a house with between 180 and 200sqm",
  scoreSize180To200: "Score how well this vendor fits prospects who whant to build a house with between 180 and 200sqm",
  filterSize200To225: "Switch on for this vendor to receive leads with prospects who whant to build a house with between 225 and 250sqm",
  scoreSize200To225: "Score how well this vendor fits prospects who whant to build a house with between 225 and 250sqm",
  filterSize225To250: "Switch on for this vendor to receive leads with prospects who whant to build a house with between 250 and 275sqm",
  scoreSize225To250: "Score how well this vendor fits prospects who whant to build a house with between 250 and 275sqm",
  filterSize250To275: "Switch on for this vendor to receive leads with prospects who whant to build a house with between 275 and 300sqm",
  scoreSize250To275: "Score how well this vendor fits prospects who whant to build a house with between 275 and 300sqm",
  filterSize275To300: "Switch on for this vendor to receive leads with prospects who whant to build a house between 275 and 300sqm",
  scoreSize275To300: "Score how well this vendor fits prospects who whant to build a house between 275 and 300sqm",
  filterSize300ToInf: "Switch on for this vendor to receive leads with prospects who whant to build a house with more than 300sqm",
  scoreSize300ToInf: "Score how well this vendor fits prospects who whant to build a house with more than 300sqm",
  scorePricePerSqm0To1000: "Score how well this vendor fits prospects who are willing to spend less than 1000€ per sqm",
  scorePricePerSqm1000To1200: "Score how well this vendor fits prospects who are willing to spend between 1000 and 1200€ per sqm",
  scorePricePerSqm1200To1400: "Score how well this vendor fits prospects who are willing to spend between 1200 and 1400€ per sqm",
  scorePricePerSqm1400To1600: "Score how well this vendor fits prospects who are willing to spend between 1400 and 1600€ per sqm",
  scorePricePerSqm1600To1800: "Score how well this vendor fits prospects who are willing to spend between 1600 and 1800€ per sqm",
  scorePricePerSqm1800To2000: "Score how well this vendor fits prospects who are willing to spend between 1800 and 2000€ per sqm",
  scorePricePerSqm2000To2200: "Score how well this vendor fits prospects who are willing to spend between 2000 and 2200€ per sqm",
  scorePricePerSqm2200To2400: "Score how well this vendor fits prospects who are willing to spend between 2200 and 2400€ per sqm",
  scorePricePerSqm2400To2600: "Score how well this vendor fits prospects who are willing to spend between 2400 and 2600€ per sqm",
  scorePricePerSqm2600To2800: "Score how well this vendor fits prospects who are willing to spend between 2600 and 2800€ per sqm",
  scorePricePerSqm2800To3000: "Score how well this vendor fits prospects who are willing to spend between 2800 and 3000€ per sqm",
  scorePricePerSqm3000ToInf: "Score how well this vendor fits prospects who are willing to spend between more than 3000€ per sqm",
  filterUtilizationApartmentHouse: "Switch on for this vendor to receive leads with prospects who are interested in building an apartment house",
  filterUtilizationDetachedHouse: "Switch on for this vendor to receive leads with prospects who are interested in building a detached house",
  filterUtilizationDuplex: "Switch on for this vendor to receive leads with prospects who are interested in building a duplex house",
  filterUtilizationTwoFamiliesHouse: "Switch on for this vendor receive leads with prospects who are interested in building a two family house",
  filterConstructionMethodBlockhouse: "Switch on for this vendor receive leads with prospects who are interested in blockhouses",
  filterConstructionMethodPrefab: "Switch on for this vendor receive leads with prospects who are interested in prefabricated houses",
  filterConstructionMethodSolid: "Switch on for this vendor receive leads with prospects who are interested in solid houses",
  filterPropertyYes: "Switch on for this vendor receive leads with prospects who own a property already",
  filterPropertyReserved: "Switch on for this vendor receive leads with prospects who have a property reserved",
  filterPropertyExpected: "Switch on for this vendor receive leads with prospects who expect a property",
  filterPropertyNo: "Switch on for this vendor receive leads with prospects who don't have a property yet",
  filterExpansionStagePriceFitting: "Switch on for this vendor receive leads with prospects who want an Ausbauhaus",
  filterExpansionStagePriceLego: "Switch on for this vendor receive leads with prospects who want a Bausatzhaus",
  filterExpansionStagePriceReady: "Switch on for this vendor receive leads with prospects who want a turnkey house",
  activatePhonePrequalification: "Switch on for this vendor to only receive leads with prospects who were prequalified",
  handoverFilter: "Defines whether a vendor does or doesn't receive certain subsets of leads either based on a certain prequal result (not reached in prequal/unwilling to give information in prequal or invalid contact details in prequal) or in the case that we can't hand the lead over to a sales office",
  handoverAssignmentPrimaryInput: "Whether we should a prospect's living location or desired building location as primary matching criterion to a vendor's sales office",
  handoverAssignmentSecondaryInputFallback: "Whether we can use also the non-primary input (living or building location) as fallback for mapping to a vendor's sales office",
  handoverRegions: "ZIP codes in which vendor offers its services",
  msHandoverRegions: "ZIP codes in which vendor offers its services",
  imHandoverRegions: "ZIP codes in which vendor offers its services",
  sendDailyReporting: "Should the vendor receive leads in a daily summary email",
  dailyReportingFormat: "Which reporting template should be used for the daily lead handover",
  dailyReportingEmails: "Who should receive the daily lead handover",
  sendInstantReporting: "Should the vendor receive every new lead instantly",
  instantReportingFormat: "Which reporting template should be used for immediate lead handover",
  instantReportingEmails: "Who should receive the immediate lead handover",
  instantReportingApi: "",
  instantReportingApiEndpoint: "",
  instantReportingApiExtraPayload: "",
  instantReportingApiHeader: "",
  instantReportingApiPreset: "",
  instantReportingCsv: "Whether the vendor should receive a CSV of the lead in the immediate lead handover. Uses the 'Daily email handover format'",
  activateHandoverToSalesAgent: "Should contact ready leads also be sent directly to sales agents?",
  disableSalesAgentApp: "Disables all Sales Agent App functionality for this vendor",
  salesAgentAppDisableEmailFeedbackButtons: "Disables the feedback buttons in the Sales Agent App handover emails",
  salesAgentAppDisableEmailSignupLink: "Disables the signup link in the Sales Agent App handover emails",
  salesAgentAppDisableOpenOpportunityReminderEmail: "Disables the reminder email for open opportunities",
  directHandoverTemplate: "Which email template should be used for the lead handover email to sales agents",
  factSheetId: "Which template should be used for the lead summary that is attached as a pdf to the handover email",
  activateNoContactDesiredToSalesAgents: "Should not only contact ready but also info only leads be sent directly to sales agents as well",
  sendDailyReportingToSalesOffices: "Should sales offices also receive a daily summary email of leads handed over",
  monthlyVendorReportRecipients: "Recipients of the monthly vendor report",
  imMonthlyVendorReportRecipients: "Recipients of the IM monthly vendor report",
  leadFeedbackReportRecipients: "Recipients of the monthly lead feedback report for this vendor",
  accountOwner: "Neuraum employee who is displayed in reports to the vendor",
  anonymizationRequestEmails: "Who should receive prospect anonymization requests",
	msSendHtmlInstantHandover: "Should the vendor receive every new MS lead instantly",
	msHtmlInstantHandoverTemplate: "Which reporting template should be used for immediate MS lead handover",
	msHtmlInstantHandoverEmails: "Who should receive the immediate MS lead handover email",
	msHtmlInstantHandoverEmailSubject: "The subject of the immediate MS lead handover email",
	msSendCsvHandover: "Should the vendor receive a CSV file email reporting",
	msCsvHandoverSchedule: "The schedule of the CSV file email reporting",
	msCsvHandoverFormat: "The format of the CSV file email reporting",
	msCsvHandoverEmails: "Who should receive the CSV file email reporting",
	msCsvHandoverEmailSubject: "The subject of the CSV file email reporting",
	msCsvHandoverTemplate: "The template of the CSV file email reporting",
	sendLeadDuplicateReporting: "Should the vendor receive a daily email of leads of the previous day to report duplicate leads",
	leadDuplicateReportingEmails: "The recipients of the daily email of leads of the previous day to report duplicate leads",
	leadDuplicateReportingFormat: "The format of the daily email of leads of the previous day to report duplicate leads",
	exclusiveLeadsClientNid: "The NID of the client this vendors exclusive leads should be assigned to",
	exclusiveLeadsAttachmentFormat: "The format of the attachment of the exclusive leads handover email",
	exclusiveLeadsHandoverEmails: "The recipients of the exclusive leads handover email",
	imProductId: "Vendor ID set in the IM management UI",
	imCatalogueId: "Catalogue ID set in the IM management UI",
	imLandingPageId: "Landing page ID set in the IM management UI",
	imSalesSegment: "National vendors are shown on top in the catalog funnel",
	imCatalogueImage: "Catalogue image shown on the catalog funnel",
	imPreselected: "Whether the vendor is preselected in the catalog funnel",
	exclusiveLeadsSendInstantReporting: "Should the vendor receive every new exclusive lead instantly",
	exclusiveLeadsSendDailyReporting: "Should the vendor receive exclusive leads in a daily summary email",
}

export default VendorTooltips;