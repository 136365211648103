<template>
    <div>
        <CCard>
            <CCardHeader color="info" text-color="white">
                Client Data
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol sm="8">
                        <CInput
                                data-sel="ipt-name"
                                :value="client.name"
                                v-on:change="setDebitorName"
                                description="Name"
                                :readonly="!allowEdit"
                                :isValid="notNull"
                                placeholder="Enter name"
                                @input="client.name = $event !== '' ? $event : null"
                        />
                    </CCol>
                    <CCol sm="4">
                        <multiselect
                                data-sel="slct-slssgm"
                                :disabled="!allowEdit"
                                v-model="client.salesSegment"
                                @input="updateItem"
                                :options="Object.keys(this.salesSegmentOptions)"
                                :custom-label="(item) => this.salesSegmentOptions[item]"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="false"
                                placeholder="Select sales segment"
                                select-label="Select"
                                deselect-label="Required field"
                        />
                        <CRow>
                            <small class="form-text text-muted w-100">Segment</small>
                        </CRow>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="4">
                        <multiselect
                                data-sel="slct-vndr"
                                :disabled="!allowEdit"
                                v-model="client.vendor"
                                @input="updateItem"
                                :options="this.vendors ? this.vendors.data ? this.vendors.data: []: []"
                                :show-labels="true"
                                label="name"
                                track-by="name"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="true"
                                select-label="Select"
                                deselect-label="Remove"
                                placeholder="Vendor"
                                v-on:search-change="updateVendorQ"
                        />
                        <CRow>
                            <small class="form-text text-muted w-100">Vendor</small>
                        </CRow>
                    </CCol>
                    <CCol sm="4">
                        <CInput
                                data-sel="ipt-slsreg"
                                :value="client.salesRegion ? client.salesRegion.nid : ''"
                                description="Sales Region NID (deprecated)"
                                :readonly="true"
                        />
                    </CCol>
                    <CCol sm="4">
                        <multiselect
                                data-sel="slct-slsoff"
                                :disabled="!allowEdit"
                                v-model="client.salesOffice"
                                @input="updateItem"
                                :options="this.salesOffices ? this.salesOffices.data? this.salesOffices.data: []: []"
                                :show-labels="true"
                                label="name"
                                track-by="name"
                                :searchable="true"
                                :close-on-select="true"
                                :allow-empty="true"
                                select-label="Select"
                                deselect-label="Remove"
                                placeholder="Sales office"
                                v-on:search-change="updateSalesOfficeQ"
                        />
                        <CRow>
                            <small class="form-text text-muted w-100">Sales office</small>
                        </CRow>
                    </CCol>
                </CRow>
                <CRow class="pt-3">
                    <CCol sm="6">
                        <CCard class="border-info">
                            <CCardHeader><div class="badge-pw">PW</div>Account Data for PW</CCardHeader>
                            <CCardBody>
                                <CRow class="ms-row">
                                    <CCol sm="3">
                                        <CButton
                                                class="btn-billomat-id"
                                                :href="billomatUrl"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                color="secondary"
                                        >
                                            {{ client.debitorNumber ? client.debitorNumber : 'Not set yet' }}
                                        </CButton>
                                        <CRow>
                                            <small class="form-text text-muted w-100">PW Debitor Number</small>
                                        </CRow>
                                    </CCol>
                                    <CCol sm="3">
                                        <CButton
                                                class="btn-billomat-id"
                                                :href="billomatUrl"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                color="secondary"
                                                :disabled="!client.billomatId"
                                        >
                                            {{ client.billomatId ? client.billomatId : 'Not set yet' }}
                                        </CButton>
                                        <CRow>
                                            <small class="form-text text-muted w-100">PW Billomat ID</small>
                                        </CRow>
                                    </CCol>
                                    <CCol sm="6">
                                        <multiselect
                                                data-sel="slct-ao"
                                                :disabled="!allowEdit"
                                                v-model="client.accountOwner"
                                                @input="updateItem"
                                                :options="this.accountOwners ? this.accountOwners.data ? this.accountOwners.data: []: []"
                                                :show-labels="true"
                                                :custom-label="fullName"
                                                track-by="nid"
                                                :searchable="true"
                                                :close-on-select="true"
                                                :allow-empty="true"
                                                select-label="Select"
                                                deselect-label="Remove"
                                                placeholder="Select account owner"
                                        />
                                        <CRow>
                                            <small class="form-text text-muted w-100">PW Account owner</small>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol sm="6">
                        <CCard class="border-danger">
                            <CCardHeader><div class="badge-ms">MS</div>Account Data for MS</CCardHeader>
                            <CCardBody>
                                <CRow class="ms-row">

                                    <CCol sm="3">
                                        <CButton
                                                class="btn-billomat-id"
                                                :href="msBillomatUrl"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                color="secondary"
                                        >
                                            {{ client.msDebitorNumber ? client.msDebitorNumber : 'Not set yet' }}
                                        </CButton>
                                        <CRow>
                                            <small class="form-text text-muted w-100">MS Debitor Number</small>
                                        </CRow>
                                    </CCol>

                                    <CCol sm="3">
                                        <CButton
                                                class="btn-billomat-id"
                                                :href="msBillomatUrl"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                color="secondary"
                                                :disabled="!client.msBillomatId"
                                        >
                                            {{ client.msBillomatId ? client.msBillomatId : 'Not set yet' }}
                                        </CButton>
                                        <CRow>
                                            <small class="form-text text-muted w-100">MS Billomat ID</small>
                                        </CRow>
                                    </CCol>
                                    <CCol sm="6">
                                        <multiselect
                                                data-sel="slct-ao-ms"
                                                :disabled="!allowEdit"
                                                v-model="client.msAccountOwner"
                                                @input="updateItem"
                                                :options="this.accountOwners ? this.accountOwners.data ? this.accountOwners.data: []: []"
                                                :show-labels="true"
                                                :custom-label="fullName"
                                                track-by="nid"
                                                :searchable="true"
                                                :close-on-select="true"
                                                :allow-empty="true"
                                                select-label="Select"
                                                deselect-label="Remove"
                                                placeholder="Select account owner"
                                        />
                                        <CRow>
                                            <small class="form-text text-muted w-100">MS Account owner</small>
                                        </CRow>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>

                </CRow>
            </CCardBody>
        </CCard>
        <FormDetailSection
                data-sel="btn-sctn-cnct"
                section="Contact"
                v-bind:showSection="showContact"
                v-on:toggle="toggleContact"
        />
        <CCollapse
                :show.sync="showContact"
                :duration="1"
        >
            <CCard>
                <CCardHeader color="info" text-color="white">
                    Client Contact
                </CCardHeader>
                <CCardBody>
                    <CRow>
                <CCol sm="12">
                    <CInput
                            data-sel="ipt-strt"
                            v-model="client.street"
                            v-on:change="setDebitorStreet"
                            placeholder="Enter street"
                            description="Street"
                            :readonly="!allowEdit"
                            @input="client.street = $event !== '' ? $event : null"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="4">
                    <multiselect
                            data-sel="slct-cntry"
                            :disabled="!allowEdit"
                            v-model="client.country"
                            @input="setDebitorCountry"
                            :options="this.countries ? this.countries.data? this.countries.data: []: []"
                            :show-labels="true"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="true"
                            select-label="Select"
                            deselect-label="Remove"
                            placeholder="Select country"
                    />
                    <CRow>
                        <small class="form-text text-muted w-100">Country</small>
                    </CRow>
                </CCol>
                <CCol sm="3">
                    <multiselect
                            data-sel="slct-zip"
                            :disabled="!allowEdit"
                            v-model="client.postalCode"
                            @input="setDebitorPostalCode"
                            :options="this.postalCodes ? postalCodes.data? postalCodes.data: []: []"
                            :show-labels="true"
                            label="postalCode"
                            track-by="postalCode"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="true"
                            select-label="Select"
                            deselect-label="Remove"
                            placeholder="Select postal code"
                            v-on:search-change="updatePostalCodeQ"
                    />
                    <CRow>
                        <small class="form-text text-muted w-100">Postal code</small>
                    </CRow>
                </CCol>
                <CCol sm="5">
                    <CInput
                            data-sel="ipt-city"
                            v-model="client.city"
                            v-on:change="setDebitorCity"
                            placeholder="Enter City"
                            description="City"
                            :readonly="!allowEdit"
                            @input="client.city = $event !== '' ? $event : null"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="6">
                    <CInput
                            data-sel="ipt-hp"
                            v-model="client.homepage"
                            v-on:change="updateItem"
                            placeholder="Enter Homepage"
                            description="Homepage"
                            :readonly="!allowEdit"
                            @input="client.homepage = $event !== '' ? $event : null"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="6">
                    <CCard class="border-info">
                            <CCardHeader><div class="badge-pw">PW</div>Contact Data for PW</CCardHeader>
                        <CCardBody>
                            <CInput
                            data-sel="ipt-email"
                            v-model="client.email"
                            v-on:change="updateItem"
                            placeholder="Enter Email for PW Internet"
                            description="PW - Email"
                            :is-valid="validEmail"
                            tooltip-feedback
                            invalid-feedback="Please enter a valid email address"
                            :readonly="!allowEdit"
                            @input="client.email = $event !== '' ? $event : null"
                    />
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol sm="6">
                    <CCard class="border-danger">
                            <CCardHeader><div class="badge-ms">MS</div>Contact Data for MS</CCardHeader>
                        <CCardBody>
                            <CInput
                            data-sel="ipt-msemail"
                            v-model="client.msEmail"
                            v-on:change="updateItem"
                            placeholder="Enter Email for MS Media"
                            description="MS - Email"
                            :is-valid="validEmail"
                            tooltip-feedback
                            invalid-feedback="Please enter a valid email address"
                            :readonly="!allowEdit"
                            @input="client.msEmail = $event !== '' ? $event : null"
                    />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
                </CCardBody>
            </CCard>


        </CCollapse>
        <FormDetailSection
                data-sel="btn-sctn-bllng"
                section="Billing"
                v-bind:showSection="showBilling"
                v-on:toggle="toggleBilling"
        />
        <CCollapse
                :show.sync="showBilling"
                :duration="1"
        >
            <CCard>
        <CCardHeader color="info" text-color="white">
          Global Billing Settings
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol sm="8">
                    <CInput
                            data-sel="ipt-deb-name"
                            v-model="client.debitorName"
                            v-on:change="updateItem"
                            placeholder="Enter debitor name"
                            description="Debitor name"
                            :readonly="!allowEdit"
                            :isValid="notNull"
                    />
                </CCol>
                <CCol sm="4">
                    <multiselect
                            data-sel="slct-clntloc"
                            :disabled="!allowEdit"
                            v-model="client.location"
                            @input="updateItem"
                            :options="Object.keys(this.locationOptions)"
                            :custom-label="(item) => this.locationOptions[item]"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="false"
                            placeholder="Select client location"
                            select-label="Select"
                            deselect-label="Required field"
                    />
                    <CRow>
                        <small class="form-text text-muted w-100">Location</small>
                    </CRow>
                </CCol>
            </CRow>

            <CRow>
                <CCol sm="6">
                    <CInput
                            data-sel="ipt-deb-strt"
                            :value="client.debitorStreet"
                            v-on:change="updateItem"
                            placeholder="Enter street"
                            description="Billing address street"
                            :isValid="notNull"
                            @input="client.debitorStreet = $event !== '' ? $event : null"
                            :readonly="!allowEdit"
                    />
                </CCol>
                <CCol sm="6">
                    <CInput
                            data-sel="ipt-deb-city"
                            :value="client.debitorCity"
                            v-on:change="updateItem"
                            placeholder="Enter City"
                            description="Billing address city"
                            :isValid="notNull"
                            @input="client.debitorCity = $event !== '' ? $event : null"
                            :readonly="!allowEdit"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="6">
                    <multiselect
                            data-sel="slct-deb-cntry"
                            :disabled="!allowEdit"
                            v-model="client.debitorCountry"
                            @input="updateItem"
                            :options="countries? countries.data ? countries.data: []: []"
                            :show-labels="true"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="false"
                            select-label="Select"
                            deselect-label="Remove"
                            placeholder="Select country"
                    />
                    <CRow>
                        <small class="form-text text-muted w-100">Billing address country</small>
                    </CRow>
                </CCol>
                <CCol sm="6">
                    <multiselect
                            data-sel="slct-deb-zip"
                            :disabled="!allowEdit"
                            v-model="client.debitorPostalCode"
                            @input="updateItem"
                            :options="postalCodes ? postalCodes.data? postalCodes.data: []: []"
                            :show-labels="true"
                            label="postalCode"
                            track-by="postalCode"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="false"
                            select-label="Select"
                            deselect-label="Remove"
                            placeholder="Select postal code"
                            v-on:search-change="updatePostalCodeQ"
                    />
                    <CRow>
                        <small class="form-text text-muted w-100">Billing address postal code</small>
                    </CRow>
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="8">
                    <CInput
                            data-sel="ipt-iban"
                            v-model="client.iban"
                            v-on:change="updateItem"
                            placeholder="Enter IBAN"
                            description="IBAN"
                            :readonly="!allowEdit"
                            @input="client.iban = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol sm="4">
                    <CInput
                            data-sel="ipt-swift"
                            v-model="client.swift"
                            v-on:change="updateItem"
                            placeholder="Enter SWIFT"
                            description="SWIFT"
                            :readonly="!allowEdit"
                            @input="client.swift = $event !== '' ? $event : null"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="6">
                    <CInput
                            data-sel="ipt-taxnum"
                            v-model="client.taxNumber"
                            v-on:change="updateItem"
                            placeholder="Enter tax number"
                            description="Tax number"
                            :readonly="!allowEdit"
                            @input="client.taxNumber = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol sm="6">
                    <CInput
                            data-sel="ipt-vatnum"
                            v-model="client.vatNumber"
                            v-on:change="updateItem"
                            placeholder="Enter VAT"
                            description="VAT number"
                            :readonly="!allowEdit"
                            @input="client.vatNumber = $event !== '' ? $event : null"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <multiselect
                            data-sel="slct-curr"
                            :disabled="!allowEdit"
                            v-model="client.currency"
                            @input="updateItem"
                            :options="Object.keys(this.currencyOptions)"
                            :custom-label="(item) => this.currencyOptions[item]"
                            :searchable="true"
                            :close-on-select="true"
                            :allow-empty="false"
                            placeholder="Select currency"
                            select-label="Select"
                            deselect-label="Required field"
                    />
                    <CRow>
                        <small class="form-text text-muted w-100">Currency</small>
                    </CRow>
                </CCol>
                <CCol>
                    <CInput
                            data-sel="ipt-vat"
                            v-on:update:value="setVAT"
                            v-on:change="updateItem"
                            description="VAT"
                            type="number"
                            @wheel="$event.target.blur()"
                            min="0"
                            max="100"
                            step="1"
                            append="%"
                            :value="clientVAT"
                            :readonly="!allowEdit"
                    >
                    </CInput>
                </CCol>
            </CRow>
        </CCardBody>
            </CCard>

        </CCollapse>
        <FormDetailSection
                data-sel="btn-sctn-bllng-pw"
                section="PW Billing Settings"
                v-bind:showSection="showPwBilling"
                v-on:toggle="showPwBilling = !showPwBilling"
        />
        <CCollapse
                :show.sync="showPwBilling"
                :duration="1"
        >
            <CCard class="border-info">
        <CCardHeader>
           <div class="badge-pw">PW</div>Billing Settings for PW
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol
                        sm="6"
                        v-if="!allowEdit"
                >
                    <CButton
                            class="btn-billomat-id"
                            :href="billomatUrl"
                            target="_blank"
                            rel="noreferrer noopener"
                            color="secondary"
                    >
                        {{ client.debitorNumber ? client.debitorNumber : 'Not set yet' }}
                    </CButton>
                    <CRow>
                        <small class="form-text text-muted w-100">Debitor Number</small>
                    </CRow>
                </CCol>
                <CCol
                        sm="6"
                        v-if="allowEdit"
                >
                    <CInput
                            data-sel="ipt-deb-num"
                            v-model="client.debitorNumber"
                            placeholder="Enter debitor number"
                            description="Debitor Number"
                            :readonly="!allowEdit"
                            @input="client.debitorNumber = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol
                        sm="6"
                        v-if="!allowEdit"
                >
                    <CButton
                            class="btn-billomat-id"
                            :href="billomatUrl"
                            target="_blank"
                            rel="noreferrer noopener"
                            color="secondary"
                            :disabled="!client.billomatId"
                    >
                        {{ client.billomatId ? client.billomatId : 'Not set yet' }}
                    </CButton>
                    <CRow>
                        <small class="form-text text-muted w-100">Billomat ID</small>
                    </CRow>
                </CCol>
                <CCol
                        sm="6"
                        v-if="allowEdit"
                >
                    <CInput
                            data-sel="ipt-billid"
                            v-model="client.billomatId"
                            placeholder="Enter Billomat ID"
                            description="Billomat ID"
                            :readonly="!allowEdit"
                            @input="client.billomatId = $event !== '' ? $event : null"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="2">
                    <CInput
                            data-sel="ipt-deb-sal"
                            :value="client.debitorContactSalutation"
                            v-on:change="updateItem"
                            placeholder="Enter salutation"
                            description="PW - Billing contact salutation"
                            :readonly="!allowEdit"
                            @input="client.debitorContactSalutation = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol sm="5">
                    <CInput
                            data-sel="ipt-deb-fname"
                            :value="client.debitorContactFirstName"
                            v-on:change="updateItem"
                            placeholder="Enter given name"
                            description="PW - Billing contact given name"
                            :readonly="!allowEdit"
                            @input="client.debitorContactFirstName = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol sm="5">
                    <CInput
                            data-sel="ipt-deb-lname"
                            :value="client.debitorContactLastName"
                            v-on:change="updateItem"
                            placeholder="Enter surname"
                            description="PW - Billing contact surname"
                            :readonly="!allowEdit"
                            @input="client.debitorContactLastName = $event !== '' ? $event : null"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="4">
                    <CInput
                            data-sel="ipt-deb-email"
                            :value="client.debitorEmail"
                            v-on:change="updateItem"
                            placeholder="Enter email"
                            description="PW - Billing email"
                            :readonly="!allowEdit"
                            :isValid="validEmail"
                            @input="client.debitorEmail = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol sm="4">
                    <CInput
                            v-model="client.sepaMandate"
                            description="PW SEPA Mandate"
                            placeholder="No mandate"
                            :readonly="true"
                    >
                    </CInput>
                </CCol>
                <CCol sm="4">
                    <CInput
                            data-sel="date-sepa"
                            v-model="client.sepaDate"
                            v-on:change="updateItem"
                            type="date"
                            description="PW SEPA date"
                            :readonly="!allowEdit"
                    >
                        <template #prepend-content>
                            <CButtonClose
                                    v-if="allowEdit"
                                    color="primary"
                                    @click="client.sepaDate = null"
                                    v-on:click="updateItem"
                            />
                        </template>
                    </CInput>
                </CCol>
            </CRow>
        </CCardBody>
            </CCard>
        </CCollapse>
        <FormDetailSection
                data-sel="btn-sctn-bllng-ms"
                section="MS Billing Settings"
                v-bind:showSection="showMsBilling"
                v-on:toggle="showMsBilling = !showMsBilling"
        />
        <CCollapse
                :show.sync="showMsBilling"
                :duration="1"
        >
            <CCard class="border-danger">
        <CCardHeader>
           <div class="badge-ms">MS</div>Billing Settings for MS
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol
                        sm="6"
                        v-if="!allowEdit"
                >
                    <CButton
                            class="btn-msbillomat-id"
                            :href="billomatUrl"
                            target="_blank"
                            rel="noreferrer noopener"
                            color="secondary"
                    >
                        {{ client.msDebitorNumber ? client.msDebitorNumber : 'Not set yet' }}
                    </CButton>
                    <CRow>
                        <small class="form-text text-muted w-100">MS Debitor Number</small>
                    </CRow>
                </CCol>
                <CCol
                        sm="6"
                        v-if="allowEdit"
                >
                    <CInput
                            data-sel="ipt-msdeb-num"
                            v-model="client.msDebitorNumber"
                            placeholder="Enter debitor number"
                            description="Debitor Number"
                            :readonly="!allowEdit"
                            @input="client.msDebitorNumber = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol
                        sm="6"
                        v-if="!allowEdit"
                >
                    <CButton
                            class="btn-msbillomat-id"
                            :href="msBillomatUrl"
                            target="_blank"
                            rel="noreferrer noopener"
                            color="secondary"
                            :disabled="!client.msBillomatId"
                    >
                        {{ client.msBillomatId ? client.msBillomatId : 'Not set yet' }}
                    </CButton>
                    <CRow>
                        <small class="form-text text-muted w-100">MS Billomat ID</small>
                    </CRow>
                </CCol>
                <CCol
                        sm="6"
                        v-if="allowEdit"
                >
                    <CInput
                            data-sel="ipt-msbillid"
                            v-model="client.msBillomatId"
                            placeholder="Enter Billomat ID"
                            description="Billomat ID"
                            :readonly="!allowEdit"
                            @input="client.msBillomatId = $event !== '' ? $event : null"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="2">
                    <CInput
                            data-sel="ipt-deb-mssal"
                            :value="client.msDebitorContactSalutation"
                            v-on:change="updateItem"
                            placeholder="Enter salutation"
                            description="MS - Billing contact salutation"
                            :readonly="!allowEdit"
                            @input="client.msDebitorContactSalutation = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol sm="5">
                    <CInput
                            data-sel="ipt-deb-msfname"
                            :value="client.msDebitorContactFirstName"
                            v-on:change="updateItem"
                            placeholder="Enter given name"
                            description="MS - Billing contact given name"
                            :readonly="!allowEdit"
                            @input="client.msDebitorContactFirstName = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol sm="5">
                    <CInput
                            data-sel="ipt-deb-mslname"
                            :value="client.msDebitorContactLastName"
                            v-on:change="updateItem"
                            placeholder="Enter surname"
                            description="MS - Billing contact surname"
                            :readonly="!allowEdit"
                            @input="client.msDebitorContactLastName = $event !== '' ? $event : null"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="4">
                    <CInput
                            data-sel="ipt-deb-msemail"
                            :value="client.msDebitorEmail"
                            v-on:change="updateItem"
                            placeholder="Enter email"
                            description="MS - Billing email"
                            :readonly="!allowEdit"
                            :isValid="validEmail"
                            @input="client.msDebitorEmail = $event !== '' ? $event : null"
                    />
                </CCol>
                <CCol sm="4">
                    <CInput
                            v-model="client.msSepaMandate"
                            description="MS SEPA Mandate"
                            placeholder="No mandate"
                            :readonly="true"
                    >
                    </CInput>
                </CCol>
                <CCol sm="4">
                    <CInput
                            data-sel="date-ms-sepa"
                            v-model="client.msSepaDate"
                            v-on:change="updateItem"
                            type="date"
                            description="MS SEPA date"
                            :readonly="!allowEdit"
                    >
                        <template #prepend-content>
                            <CButtonClose
                                    v-if="allowEdit"
                                    color="primary"
                                    @click="client.msSepaDate = null"
                                    v-on:click="updateItem"
                            />
                        </template>
                    </CInput>
                </CCol>
            </CRow>
        </CCardBody></CCard>
        </CCollapse>
        <FormDetailSection
                section="Portfolio Settings"
                v-bind:showSection="showPortfolioSettings"
                v-on:toggle="showPortfolioSettings = !showPortfolioSettings"
        />
        <CCollapse
                :show.sync="showPortfolioSettings"
                :duration="1"
        >
            <CCard>
        <CCardHeader color="info" text-color="white">
          Portfolio Settings
        </CCardHeader>
        <CCardBody>
            <CRow class="portfolio-settings-container justify-content-center">
                <CContainer>
                    <div v-for="item in client.portfolioSettings" :key="item.nid">
                        <ClientPortfolioSettingsForm
                                class="portfolio-settings-box"
                                v-bind:portfolioSettingsIn="item"
                                v-on:del-portfolio-settings="removePortfolioSettingsLocal"
                                v-on:update-portfolio-settings="updateItem"
                                v-on:error="(error) => $emit('error', error)"
                        />
                    </div>
                </CContainer>
                <CButton
                        data-sel="btn-add-port"
                        v-if="allowEdit"
                        @click="addPortfolioSettingsLocal"
                >
                    <CIcon name="cil-plus"/>
                </CButton>
            </CRow>
        </CCardBody>
            </CCard>
        </CCollapse>
        <FormDetailSection
                data-sel="btn-sctn-notes"
                section="Notes"
                v-bind:showSection="showNotes"
                v-on:toggle="toggleNotes"
        />
        <CCollapse
                :show.sync="showNotes"
                :duration="1"
        >
            <CCard>
        <CCardHeader color="info" text-color="white">
          Notes
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol sm="12">
                    <CCard>
                    <CCardHeader>Global Notes</CCardHeader>
                    <CCardBody>
                <CTextarea
                    description="Notes"
                    data-sel="ipt-notes"
                    v-model="client.notes"
                    v-on:change="updateItem"
                    :readonly="!allowEdit"
                    @input="client.notes = $event !== '' ? $event : null"
                /></CCardBody></CCard></CCol>
            </CRow>

            <CRow>
                <CCol sm="12">
                <CCard class="border-danger">
                    <CCardHeader><div class="badge-ms">MS</div> Description for MS</CCardHeader>
                    <CCardBody>
                        <CTextarea
                    description="MS Media Description"
                    data-sel="ipt-ms-description"
                    v-model="client.msDescription"
                    v-on:change="updateItem"
                    :readonly="!allowEdit"
                    @input="client.msDescription = $event !== '' ? $event : null"
                        />
                    </CCardBody>
                </CCard>
                    </CCol>
            </CRow>

        </CCardBody>
            </CCard>
        </CCollapse>
    </div>
</template>

<script>
import gql from "graphql-tag";
import ClientPortfolioSettingsForm from "@/apps/contracts/components/ClientPortfolioSettingsForm";
import FormDetailSection from "@/components/common/FormDetailSection";

const clonedeep = require('lodash.clonedeep')

const queryAccountOwners = gql`
query {
  accountOwners(order: "lastName") {
    data{
      nid
      firstName
      lastName
    }
   }
}
`
const queryVendors = gql`
query vendors($q: String){
  vendors(q: $q, order: "name") {
    data {
      nid
      name
    }
  }
}
`
const querySalesOffices = gql`
query salesOffices($q: String){
  salesOffices(q: $q, order: "name") {
    data {
      nid
      name
    }
  }
}
`
const queryCountries = gql`
query {
  countries(order: "name") {
    data {
      nid
      name
    }
  }
}
`
const queryPostalCodes = gql`
query postalCodes($countryNames: [String], $q: String){
  postalCodes(countryNames: $countryNames, q: $q, order: "postalCode") {
    data {
      nid
      postalCode
    }
  }
}
`

export default {
    name: "ClientForm",
    props: ["clientIn"],
    components: {
        FormDetailSection,
        ClientPortfolioSettingsForm
    },
    data() {
        return {
            accountOwners: [],
            vendors: [],
            vendorQ: null,
            salesOffices: [],
            salesOfficeQ: null,
            countries: [],
            postalCodes: [],
            postalCodeQ: null,
            salesSegmentOptions: {
                top: "Top",
                key: "Key",
                regio: "Regio",
                austria: "Austria",
                outfitter: "Outfitter",
                town_and_country: "TC",
                heinz_von_heiden: "HvH",
                ytong: "Ytong",
                finance: "Finance",
                gu: "GU",
                musterhausparks: "Musterhausparks",
                baumarkt: "Baumarkt",
                other: "other"
            },
            currencyOptions: {
                eur: "EUR"
            },
            locationOptions: {
                germany: "Germany",
                eu: "EU",
                non_eu: "Non EU"
            },
            showContact: false,
            showBilling: false,
            showMsBilling: false,
            showPwBilling: false,
            showNotes: false,
            showPortfolioSettings: false
        }
    },
    methods: {
        updateItem() {
          // logic needs to be implemented on the parent component, as the 'is valid' button is added on the parent form
          const data = clonedeep(this.client)
          this.$emit('update-item', data);
        },
        notNull(val) {
            return val !== undefined && val !== null && val !== ''
        },
        validEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return email ? re.test(email) : null;
        },
        toggleContact() {
            this.showContact = !this.showContact
        },
        toggleBilling() {
            this.showBilling = !this.showBilling
        },
        removePortfolioSettingsLocal(data) {
            this.client.portfolioSettings = this.client.portfolioSettings
                .filter(setting => setting.idx !== data.idx)
                .map((item, idx) => {
                    return {...item, idx}
                })
            this.updateItem();
        },
        addPortfolioSettingsLocal() {
            let newPortfolioSettings = {
                entity: "PW",
                filterPrice0To100k: true,
                scorePrice0To100k: "high",
                filterPrice100kTo150k: true,
                scorePrice100kTo150k: "high",
                filterPrice150kTo200k: true,
                scorePrice150kTo200k: "high",
                filterPrice200kTo250k: true,
                scorePrice200kTo250k: "high",
                filterPrice250kTo300k: true,
                scorePrice250kTo300k: "high",
                filterPrice300kTo350k: true,
                scorePrice300kTo350k: "high",
                filterPrice350kTo400k: true,
                scorePrice350kTo400k: "high",
                filterPrice400kTo450k: true,
                scorePrice400kTo450k: "high",
                filterPrice450kTo500k: true,
                scorePrice450kTo500k: "high",
                filterPrice500kTo550k: true,
                scorePrice500kTo550k: "high",
                filterPrice550kTo600k: true,
                scorePrice550kTo600k: "high",
                filterPrice600kToInf: true,
                scorePrice600kToInf: "high",
                filterSize0To75: true,
                scoreSize0To75: "high",
                filterSize75To100: true,
                scoreSize75To100: "high",
                filterSize100To120: true,
                scoreSize100To120: "high",
                filterSize120To140: true,
                scoreSize120To140: "high",
                filterSize140To160: true,
                scoreSize140To160: "high",
                filterSize160To180: true,
                scoreSize160To180: "high",
                filterSize180To200: true,
                scoreSize180To200: "high",
                filterSize200To225: true,
                scoreSize200To225: "high",
                filterSize225To250: true,
                scoreSize225To250: "high",
                filterSize250To275: true,
                scoreSize250To275: "high",
                filterSize275To300: true,
                scoreSize275To300: "high",
                filterSize300ToInf: true,
                scoreSize300ToInf: "high",
                scorePricePerSqm0To1000: "high",
                scorePricePerSqm1000To1200: "high",
                scorePricePerSqm1200To1400: "high",
                scorePricePerSqm1400To1600: "high",
                scorePricePerSqm1600To1800: "high",
                scorePricePerSqm1800To2000: "high",
                scorePricePerSqm2000To2200: "high",
                scorePricePerSqm2200To2400: "high",
                scorePricePerSqm2400To2600: "high",
                scorePricePerSqm2600To2800: "high",
                scorePricePerSqm2800To3000: "high",
                scorePricePerSqm3000ToInf: "high",
                filterUtilizationApartmentHouse: true,
                filterUtilizationDetachedHouse: true,
                filterUtilizationDuplex: true,
                filterUtilizationTwoFamiliesHouse: true,
                filterConstructionMethodBlockhouse: true,
                filterConstructionMethodPrefab: true,
                filterConstructionMethodSolid: true,
                filterPropertyYes: true,
                filterPropertyReserved: true,
                filterPropertyExpected: true,
                filterPropertyNo: true,
                filterExpansionStagePriceFitting: true,
                filterExpansionStagePriceLego: true,
                filterExpansionStagePriceReady: true,
            }
            if (typeof this.client.portfolioSettings === 'undefined') {
                this.client.portfolioSettings = [newPortfolioSettings]
                this.client.portfolioSettings = this.client.portfolioSettings ? this.client.portfolioSettings.map((item, idx) => {
                    return {...item, idx}
                }) : null;
            } else {
                this.client.portfolioSettings.push(newPortfolioSettings)
                this.client.portfolioSettings = this.client.portfolioSettings ? this.client.portfolioSettings.map((item, idx) => {
                    return {...item, idx}
                }) : null;
            }
            this.updateItem();
        },
        toggleNotes() {
            this.showNotes = !this.showNotes
        },
        setDebitorName() {
            if (this.client.debitorName === null || this.client.debitorName === undefined) {
                this.client.debitorName = this.client.name;
            }
            this.updateItem();
        },
        setDebitorStreet() {
            if (this.client.debitorStreet === null || this.client.debitorStreet === undefined) {
                this.client.debitorStreet = this.client.street;
            }
            this.updateItem();
        },
        setDebitorPostalCode() {
            if (this.client.debitorPostalCode === null || this.client.debitorPostalCode === undefined) {
                this.client.debitorPostalCode = this.client.postalCode;
            }
            this.updateItem();
        },
        setDebitorCity() {
            if (this.client.debitorCity === null || this.client.debitorCity === undefined) {
                this.client.debitorCity = this.client.city;
            }
            this.updateItem();
        },
        setDebitorCountry() {
            if (this.client.debitorCountry === null || this.client.debitorCountry === undefined) {
                this.client.debitorCountry = this.client.country;
            }
            this.updateItem();
        },
        setVAT(val) {
            this.client.vat = val / 100;
        },
        updateVendorQ(event) {
            this.vendorQ = event
        },
        updateSalesOfficeQ(event) {
            this.salesOfficeQ = event
        },
        updatePostalCodeQ(event) {
            this.postalCodeQ = event
        },
        fullName({firstName, lastName}) {
            return `${firstName} ${lastName}`;
        }
    },
    apollo: {
        accountOwners: {
            query: queryAccountOwners,
            error(error) {
                console.error(error);
                this.$emit("error", JSON.stringify(error));
            }
        },
        vendors: {
            query: queryVendors,
            variables() {
                return {
                    q: this.vendorQ
                }
            },
            error(error) {
                console.error(error);
                this.$emit("error", JSON.stringify(error));
            }
        },
        salesOffices: {
            query: querySalesOffices,
            variables() {
                return {
                    q: this.salesOfficeQ
                }
            },
            error(error) {
                console.error(error);
                this.$emit("error", JSON.stringify(error));
            }
        },
        countries: {
            query: queryCountries,
            error(error) {
                console.error(error);
                this.$emit("error", JSON.stringify(error));
            }
        },
        postalCodes: {
            query: queryPostalCodes,
            variables() {
                return {
                    countryNames: this.client.country ? [this.client.country.name] : null,
                    q: this.postalCodeQ
                }
            },
            error(error) {
                console.error(error);
                this.$emit("error", JSON.stringify(error));
            }
        }
    },
    computed: {
        client() {
            return this.clientIn ? this.clientIn : {}
        },
        allowEdit: {
            get() {
                return this.$store.state.allowEdit
            },
            set() {
            }
        },
        clientVAT() {
            const vat = this.client.vat * 100
            this.setVAT(vat);
            return vat
        },
        billomatUrl() {
            if (this.client && this.client.billomatId) {
                return `https://pwinternetsolutions.billomat.net/app/beta/masterdata/clients/${this.client.billomatId}/`
            } else {
                return 'https://pwinternetsolutions.billomat.net/app/beta/masterdata/clients'
            }
        },
        msBillomatUrl() {
            if (this.client && this.client.msBillomatId) {
                return `https://msmediasystems.billomat.net/app/beta/masterdata/clients/${this.client.msBillomatId}/`
            } else {
                return 'https://msmediasystems.billomat.net/app/beta/masterdata/clients'
            }
        }
    },
    watch: {
        clientIn() {
            this.updateItem();
        },
        client(){
          this.updateItem();
        }
    }
}
</script>

<style scoped>
.badge-ms {
  background-color: #9c0725 !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pw {
  background-color: #0083aa !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-im {
  background-color: #fc0 !important;
  white-space: normal !important;
  color: #303038 !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.multiselect {
    border-width: 2pt;;
    list-style-type: none;
    margin-bottom: 1pt;
}

.ms-row {
    margin-bottom: .75em;
}

.switch {
    margin-bottom: .2em;
    margin-top: .15em;
}

.btn-billomat-id {
    width: 100%
}
.btn-msbillomat-id {
    width: 100%
}
</style>
