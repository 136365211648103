<template>
  <CRow class="align-items-center">
    <CCol sm="1">
      <CRow class="justify-content-center">
        <CButtonClose
            v-if="allowEdit"
            buttonClasses="text-danger close"
            @click="deleteItem"
        />
      </CRow>
    </CCol>
    <CCol sm="11">
        <CCard :class="entityBorderClass" >
            <CCardHeader><span v-html="entityBadge" />Portfolio Setting for {{portfolioSettings.entity}}</CCardHeader>
            <CCardBody>
                <CRow class="row-pos ms-row">
        <CCol>
          <multiselect
              data-sel="slct-entity"
              :disabled="!allowEdit"
              v-model="portfolioSettings.entity"
              @input="updateItem"
              :options="this.entityOptions? Object.keys(this.entityOptions): []"
              :custom-label="(item) => this.entityOptions ? this.entityOptions[item]: ''"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="false"
              placeholder="Select entity"
              select-label="Select"
              deselect-label="Required field"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
         <CCard body-wrapper>
            <CCardHeader>
              <h6>
                Price
              </h6>
            </CCardHeader>
            <CCardBody>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  &#60; 100k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice0To100k"
                      v-c-tooltip="{content: tooltips.filterPrice0To100k? tooltips.filterPrice0To100k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice0To100k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice0To100k"
                      v-c-tooltip="{content: tooltips.scorePrice0To100k? tooltips.scorePrice0To100k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  100k - 150k €
                </CCol>
                <CCol class="switch-col col-sm-2" data-sel="btn-prc-100-150">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice100kTo150k"
                      v-c-tooltip="{content: tooltips.filterPrice100kTo150k? tooltips.filterPrice100kTo150k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      data-sel="slct-prc-100-150"
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice100kTo150k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice100kTo150k"
                      v-c-tooltip="{content: tooltips.scorePrice100kTo150k? tooltips.scorePrice100kTo150k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  150k - 200k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice150kTo200k"
                      v-c-tooltip="{content: tooltips.filterPrice150kTo200k? tooltips.filterPrice150kTo200k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice150kTo200k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice150kTo200k"
                      v-c-tooltip="{content: tooltips.scorePrice150kTo200k? tooltips.scorePrice150kTo200k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  200k - 250k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice200kTo250k"
                      v-c-tooltip="{content: tooltips.filterPrice200kTo250k? tooltips.filterPrice200kTo250k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice200kTo250k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice200kTo250k"
                      v-c-tooltip="{content: tooltips.scorePrice200kTo250k? tooltips.scorePrice200kTo250k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  250k - 300k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice250kTo300k"
                      v-c-tooltip="{content: tooltips.filterPrice250kTo300k? tooltips.filterPrice250kTo300k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice250kTo300k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice250kTo300k"
                      v-c-tooltip="{content: tooltips.scorePrice250kTo300k? tooltips.scorePrice250kTo300k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  300k - 350k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice300kTo350k"
                      v-c-tooltip="{content: tooltips.filterPrice300kTo350k? tooltips.filterPrice300kTo350k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice300kTo350k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice300kTo350k"
                      v-c-tooltip="{content: tooltips.scorePrice300kTo350k? tooltips.scorePrice300kTo350k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  350k - 400k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice350kTo400k"
                      v-c-tooltip="{content: tooltips.filterPrice350kTo400k? tooltips.filterPrice350kTo400k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice350kTo400k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice350kTo400k"
                      v-c-tooltip="{content: tooltips.scorePrice350kTo400k? tooltips.scorePrice350kTo400k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  400k - 450k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice400kTo450k"
                      v-c-tooltip="{content: tooltips.filterPrice400kTo450k? tooltips.filterPrice400kTo450k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice400kTo450k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice400kTo450k"
                      v-c-tooltip="{content: tooltips.scorePrice400kTo450k? tooltips.scorePrice400kTo450k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  450k - 500k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice450kTo500k"
                      v-c-tooltip="{content: tooltips.filterPrice450kTo500k? tooltips.filterPrice450kTo500k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice450kTo500k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice450kTo500k"
                      v-c-tooltip="{content: tooltips.scorePrice450kTo500k? tooltips.scorePrice450kTo500k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  500k - 550k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice500kTo550k"
                      v-c-tooltip="{content: tooltips.filterPrice500kTo550k? tooltips.filterPrice500kTo550k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice500kTo550k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice500kTo550k"
                      v-c-tooltip="{content: tooltips.scorePrice500kTo550k? tooltips.scorePrice500kTo550k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  550k - 600k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice550kTo600k"
                      v-c-tooltip="{content: tooltips.filterPrice550kTo600k? tooltips.filterPrice550kTo600k: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice550kTo600k"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice550kTo600k"
                      v-c-tooltip="{content: tooltips.scorePrice550kTo600k? tooltips.scorePrice550kTo600k: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  &#62; 600k €
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterPrice600kToInf"
                      v-c-tooltip="{content: tooltips.filterPrice600kToInf? tooltips.filterPrice600kToInf: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterPrice600kToInf"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scorePrice600kToInf"
                      v-c-tooltip="{content: tooltips.scorePrice600kToInf? tooltips.scorePrice600kToInf: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="4">
         <CCard body-wrapper>
            <CCardHeader>
              <h6>
                Size
              </h6>
            </CCardHeader>
            <CCardBody>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  &#60; 75 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize0To75"
                      v-c-tooltip="{content: tooltips.filterSize0To75? tooltips.filterSize0To75: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize0To75"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize0To75"
                      v-c-tooltip="{content: tooltips.scoreSize0To75? tooltips.scoreSize0To75: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  75 - 100 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize75To100"
                      v-c-tooltip="{content: tooltips.filterSize75To100? tooltips.filterSize75To100: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize75To100"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize75To100"
                      v-c-tooltip="{content: tooltips.scoreSize75To100? tooltips.scoreSize75To100: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  100 - 120 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize100To120"
                      v-c-tooltip="{content: tooltips.filterSize100To120? tooltips.filterSize100To120: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize100To120"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize100To120"
                      v-c-tooltip="{content: tooltips.scoreSize100To120? tooltips.scoreSize100To120: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  120 - 140 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize120To140"
                      v-c-tooltip="{content: tooltips.filterSize120To140? tooltips.filterSize120To140: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize120To140"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize120To140"
                      v-c-tooltip="{content: tooltips.scoreSize120To140? tooltips.scoreSize120To140: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  140 - 160 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize140To160"
                      v-c-tooltip="{content: tooltips.filterSize140To160? tooltips.filterSize140To160: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize140To160"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize140To160"
                      v-c-tooltip="{content: tooltips.scoreSize140To160? tooltips.scoreSize140To160: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  160 - 180 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize160To180"
                      v-c-tooltip="{content: tooltips.filterSize160To180? tooltips.filterSize160To180: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize160To180"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize160To180"
                      v-c-tooltip="{content: tooltips.scoreSize160To180? tooltips.scoreSize160To180: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  180 - 200 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize180To200"
                      v-c-tooltip="{content: tooltips.filterSize180To200? tooltips.filterSize180To200: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize180To200"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize180To200"
                      v-c-tooltip="{content: tooltips.scoreSize180To200? tooltips.scoreSize180To200: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  200 - 225 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2" data-sel="btn-sz-200-225">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize200To225"
                      v-c-tooltip="{content: tooltips.filterSize200To225? tooltips.filterSize200To225: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      data-sel="slct-sz-200-225"
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize200To225"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize200To225"
                      v-c-tooltip="{content: tooltips.scoreSize200To225? tooltips.scoreSize200To225: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  225 - 250 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize225To250"
                      v-c-tooltip="{content: tooltips.filterSize225To250? tooltips.filterSize225To250: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize225To250"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize225To250"
                      v-c-tooltip="{content: tooltips.scoreSize225To250? tooltips.scoreSize225To250: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  250 - 275 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize250To275"
                      v-c-tooltip="{content: tooltips.filterSize250To275? tooltips.filterSize250To275: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize250To275"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize250To275"
                      v-c-tooltip="{content: tooltips.scoreSize250To275? tooltips.scoreSize250To275: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  275 - 300 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize275To300"
                      v-c-tooltip="{content: tooltips.filterSize275To300? tooltips.filterSize275To300: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize275To300"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize275To300"
                      v-c-tooltip="{content: tooltips.scoreSize275To300? tooltips.scoreSize275To300: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
              <CRow class="switch-row">
                <CCol class="switch-prefix-col col-sm-5">
                  &#62; 300 &#13217;
                </CCol>
                <CCol class="switch-col col-sm-2">
                  <CSwitch
                      :checked.sync="portfolioSettings.filterSize300ToInf"
                      v-c-tooltip="{content: tooltips.filterSize300ToInf? tooltips.filterSize300ToInf: '', delay: tooltipDelay}"
                      v-on:update:checked	="updateItem"
                      color="info"
                      :disabled="!allowEdit"
                  />
                </CCol>
                <CCol class="switch-col col-sm-5">
                  <CSelect
                      class="w-100"
                      :disabled="!allowEdit || !portfolioSettings.filterSize300ToInf"
                      :options="scoreOptions"
                      size="sm"
                      :value.sync="portfolioSettings.scoreSize300ToInf"
                      v-c-tooltip="{content: tooltips.scoreSize300ToInf? tooltips.scoreSize300ToInf: '', delay: tooltipDelay}"
                      v-on:update:value="updateItem"
                      :readonly="!allowEdit"
                  />
                </CCol>
              </CRow>
            </CCardBody>
         </CCard>
        </CCol>
        <CCol sm="4">
         <CCard body-wrapper>
            <CCardHeader>
              <h6>
                Price per &#13217;
              </h6>
            </CCardHeader>
           <CCardBody>

             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 &#60; 1000 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm0To1000"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm0To1000? tooltips.scorePricePerSqm0To1000: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 1000 - 1200 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm1000To1200"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm1000To1200? tooltips.scorePricePerSqm1000To1200: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 1200 - 1400 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     data-sel="slct-ppm-1200-1400"
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm1200To1400"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm1200To1400? tooltips.scorePricePerSqm1200To1400: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 1400 - 1600 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm1400To1600"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm1400To1600? tooltips.scorePricePerSqm1400To1600: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 1600 - 1800 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm1600To1800"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm1600To1800? tooltips.scorePricePerSqm1600To1800: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 1800 - 2000 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm1800To2000"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm1800To2000? tooltips.scorePricePerSqm1800To2000: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 2000 - 2200 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm2000To2200"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm2000To2200? tooltips.scorePricePerSqm2000To2200: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 2200 - 2400 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm2200To2400"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm2200To2400? tooltips.scorePricePerSqm2200To2400: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 2400 - 2600 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm2400To2600"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm2400To2600? tooltips.scorePricePerSqm2400To2600: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 2600 - 2800 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm2600To2800"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm2600To2800? tooltips.scorePricePerSqm2600To2800: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 2800 - 3000 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm2800To3000"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm2800To3000? tooltips.scorePricePerSqm2800To3000: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 &#62; 3000 €/&#13217;
               </CCol>
               <CCol class="switch-col col-sm-5">
                 <CSelect
                     class="w-100"
                     :disabled="!allowEdit"
                     :options="scoreOptions"
                     size="sm"
                     :value.sync="portfolioSettings.scorePricePerSqm3000ToInf"
                     v-c-tooltip="{content: tooltips.scorePricePerSqm3000ToInf? tooltips.scorePricePerSqm3000ToInf: '', delay: tooltipDelay}"
                     v-on:update:value="updateItem"
                     :readonly="!allowEdit"
                 />
               </CCol>
             </CRow>
           </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
         <CCard body-wrapper>
           <CCardHeader>
             <h6>Utilization</h6>
           </CCardHeader>
           <CCardBody>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 Apartment house
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-uz-ah">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterUtilizationApartmentHouse"
                     v-c-tooltip="{content: tooltips.filterUtilizationApartmentHouse? tooltips.filterUtilizationApartmentHouse: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 Detached house
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-uz-dh">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterUtilizationDetachedHouse"
                     v-c-tooltip="{content: tooltips.filterUtilizationDetachedHouse? tooltips.filterUtilizationDetachedHouse: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 Duplex
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-uz-dup">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterUtilizationDuplex"
                     v-c-tooltip="{content: tooltips.filterUtilizationDuplex? tooltips.filterUtilizationDuplex: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-7">
                 Two families house
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-uz-tfh">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterUtilizationTwoFamiliesHouse"
                     v-c-tooltip="{content: tooltips.filterUtilizationTwoFamiliesHouse? tooltips.filterUtilizationTwoFamiliesHouse: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
           </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="4">
         <CCard body-wrapper>
           <CCardHeader>
              <h6>Construction method</h6>
            </CCardHeader>
           <CCardBody>

             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-5">
                 Prefab
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-cm-pf">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterConstructionMethodPrefab"
                     v-c-tooltip="{content: tooltips.filterConstructionMethodPrefab? tooltips.filterConstructionMethodPrefab: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-5">
                 Solid
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-cm-sol">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterConstructionMethodSolid"
                     v-c-tooltip="{content: tooltips.filterConstructionMethodSolid? tooltips.filterConstructionMethodSolid: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-5">
                 Blockhouse
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-cm-bh">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterConstructionMethodBlockhouse"
                     v-c-tooltip="{content: tooltips.filterConstructionMethodBlockhouse? tooltips.filterConstructionMethodBlockhouse: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
           </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="4">
         <CCard body-wrapper>
           <CCardHeader>
             <h6>Property</h6>
           </CCardHeader>
           <CCardBody>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-5">
                 Yes
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-prop-y">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterPropertyYes"
                     v-c-tooltip="{content: tooltips.filterPropertyYes? tooltips.filterPropertyYes: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-5">
                 Reserved
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-prop-res">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterPropertyReserved"
                     v-c-tooltip="{content: tooltips.filterPropertyReserved? tooltips.filterPropertyReserved: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-5">
                 Expected
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-prop-exp">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterPropertyExpected"
                     v-c-tooltip="{content: tooltips.filterPropertyExpected? tooltips.filterPropertyExpected: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-5">
                 No
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-prop-no">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterPropertyNo"
                     v-c-tooltip="{content: tooltips.filterPropertyNo? tooltips.filterPropertyNo: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
           </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4">
         <CCard body-wrapper>
           <CCardHeader>
              <h6>Expansion Stage</h6>
            </CCardHeader>
           <CCardBody>

             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-8">
                 Ready (schlüsselfertig)
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-es-pr">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterExpansionStagePriceReady"
                     v-c-tooltip="{content: tooltips.filterExpansionStagePriceReady? tooltips.filterExpansionStagePriceReady: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-8">
                 Fitting (Ausbauhaus)
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-es-pf">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterExpansionStagePriceFitting"
                     v-c-tooltip="{content: tooltips.filterExpansionStagePriceFitting? tooltips.filterExpansionStagePriceFitting: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
             <CRow class="switch-row">
               <CCol class="switch-prefix-col col-sm-8">
                 Lego (Bausatzhaus)
               </CCol>
               <CCol class="switch-col col-sm-2" data-sel="btn-es-pl">
                 <CSwitch
                     :checked.sync="portfolioSettings.filterExpansionStagePriceLego"
                     v-c-tooltip="{content: tooltips.filterExpansionStagePriceLego? tooltips.filterExpansionStagePriceLego: '', delay: tooltipDelay}"
                     v-on:update:checked	="updateItem"
                     color="info"
                     :disabled="!allowEdit"
                 />
               </CCol>
             </CRow>
           </CCardBody>
          </CCard>
        </CCol>
      </CRow>
            </CCardBody>
        </CCard>

    </CCol>
  </CRow>
</template>

<script>
import PortfolioSettingsTooltips from "@/apps/partners/tooltips/portfolio_settings_tooltips";
const clonedeep = require('lodash.clonedeep')

export default {
  name: "ClientPortfolioSettingsForm",
  props: ["portfolioSettingsIn"],
  data() {
    return {
      tooltips: PortfolioSettingsTooltips,
      tooltipDelay: 500,
      scoreOptions: ["none", "low", "medium", "high"],
      entityOptions: {
        PW: "PW Internet",
        MS: "MS Media",
        IM: "Immowelt",
      }
    }
  },
  methods: {
    updateItem(){
      this.$emit('update-portfolio-settings', this.portfolioSettings)
    },
    deleteItem(){
      this.$emit('del-portfolio-settings', this.portfolioSettings)
    },
    notNull(val) {
      return val !== undefined && val !== null && val !== ''
    },
  },
  computed: {
    portfolioSettings(){
      return this.portfolioSettingsIn? this.portfolioSettingsIn: {}
    },
    entityBorderClass(){
      return this.portfolioSettings.entity === 'PW'? 'border-info': 'border-danger'
    },
    entityBadge(){
      switch (this.portfolioSettings.entity) {
        case 'PW':
          return '<div class="badge-pw">PW</div>'
        case 'MS':
          return '<div class="badge-ms">MS</div>'
        case 'IM':
          return '<div class="badge-im">IM</div>'
        default:
          return '<div class="badge-pw">PW</div>'
      }
    },
    allowEdit: {
      get: function () {
        return this.$store.state.allowEdit
      },
      set: function () {
      }
    }
  }
}
</script>

<style scoped>
>>> .badge-ms {
  background-color: #9c0725 !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

>>> .badge-pw {
  background-color: #0083aa !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

>>> .badge-im {
  background-color: #fc0 !important;
  white-space: normal !important;
  color: #303038 !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

  .row-pos{
    margin-right: 0pt;
  }
  .multiselect {
    border-width: 2pt;
    list-style-type: none;
    margin-bottom: 1pt;
  }
  .ms-row {
    margin-bottom: .75em;
 }
  .switch {
    margin-bottom: .2em;
    margin-top: .15em;
  }
</style>
